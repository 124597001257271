import * as React from "react";
import Navbar from "../components/nav-bar";
import Footer from "../components/footer";
import Banner from "../components/banner";
import { Row, Container, Col } from "react-bootstrap";
import ReactMarkdown from "react-markdown";
import { useStaticQuery } from "gatsby";
import { graphql } from "gatsby";
const Solutions = () => {
  const { strapiSolutions } = useStaticQuery(pageQuery);
  const { content } = strapiSolutions;
  return (
    <div className="outer-container">
      <Navbar></Navbar>
      <Container fluid className="p-0 overflow-hidden">
        <Banner title="Solutions We Offer" backgroundClass="home-img"></Banner>
        <Row className="article p-5">
          <Col>
          <ReactMarkdown>{content}</ReactMarkdown>
          </Col>
        </Row>
        <Footer></Footer>
      </Container>
    </div>
  );
};

export default Solutions;

export const pageQuery = graphql`
  query SolutionsPageDataQuery {
    strapiSolutions {
          content
    }
  }
`;